import Input, { BaseInput } from './Input'
import CurrencyFormat from 'react-currency-format'

const Number: React.FC<BaseInput & { decimal?: boolean }> = ({ value, onChange, decimal = false, ...params }) => {
  return (
    <CurrencyFormat
      customInput={Input}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      decimalScale={decimal ? 2 : 0}
      fixedDecimalScale={decimal}
      value={value || ''}
      onValueChange={(v) => onChange(v.floatValue)}
      returnRawChangeEvent
      textAlign={'right'}
      {...params}
      type="text"
    />
  )
}

export default Number
