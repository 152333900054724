import { ArrowsPointingInIcon, ArrowsPointingOutIcon, EyeIcon } from '@heroicons/react/24/solid'
import Input from '../Input/Input'
import { useSelector } from 'react-redux'
import globalProps from '../../../redux/props'
import { useEffect, useState } from 'react'
import Headline from '../Headline/Headline'
import Dropdown from '../Dropdown/Dropdown'
import Button from '../Button/Button'
import Checkbox from '../Checkbox/Checkbox'
import Refresh from './Refresh'

type PropParsers = {
  [key: string]: (item: any) => string
}

type MultiOption = {
  value: string
  label: string
}

type Props = {
  title?: string
  list: any[]
  propParser: PropParsers
  translationObject: Object
  pull?: () => void
  filter: (search: string) => (item: any) => boolean
  Add?: React.FC<{ successCallback: () => void }>
  Update?: React.FC<{ successCallback: () => void; initData?: any }>
  Action?:
    | React.FC<{ successCallback?: (payload?: any) => void; initData?: any }>
    | React.FC<{ successCallback?: (payload?: any) => void; initData?: any }>[]
  linkGenerator?: (item: any) => string
  multiOptions?: MultiOption[]
  triggerMultiOption?: (option: string, uuidList: string[]) => void
}

const SimpleList: React.FC<Props> = ({
  title,
  list,
  propParser,
  translationObject,
  filter,
  pull,
  Add,
  Update,
  linkGenerator,
  Action,
  multiOptions,
  triggerMultiOption,
}) => {
  const t = useSelector((s) => s[globalProps.TRANSLATION])
  const [search, setSearch] = useState<string>('')
  const [selectActive, setSelectActive] = useState<boolean>(false)
  const [selectedMultiOption, setSelectedMultiOption] = useState<string>()
  const [selectedItemUUIDList, setSelectedItemUUIDList] = useState<string[]>([])

  useEffect(() => {
    if (!list) {
      pull()
    }
  }, [list, pull])

  const triggerOption = () => {
    if (!!triggerMultiOption && !!selectedMultiOption && selectedItemUUIDList.length > 0) {
      triggerMultiOption(selectedMultiOption, selectedItemUUIDList)
    }
  }

  const toggleSelectActive = () => setSelectActive(!selectActive)
  const toggleItemSelection = (uuid: string) => () => {
    if (selectedItemUUIDList.includes(uuid)) {
      setSelectedItemUUIDList(selectedItemUUIDList.filter((i) => i !== uuid))
    } else {
      setSelectedItemUUIDList([...selectedItemUUIDList, uuid])
    }
  }

  const isToggleAllActive = () => selectedItemUUIDList.length === list.length
  const toggleAll = () => {
    if (isToggleAllActive()) {
      setSelectedItemUUIDList([])
    } else {
      setSelectedItemUUIDList(list.filter(filter(search)).map((i) => i.uuid))
    }
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-3 justify-between items-center">
        {!!title && <Headline text={title} />}
        <div className="flex flex-row gap-3 items-center">
          {!!Add && <Add successCallback={pull} />}
          {!!pull && <Refresh pull={pull} />}
          {!!multiOptions && (
            <>
              {!selectActive && <ArrowsPointingOutIcon onClick={toggleSelectActive} className="text-blue w-5 cursor-pointer" />}
              {selectActive && <ArrowsPointingInIcon onClick={toggleSelectActive} className="text-blue w-5 cursor-pointer" />}
            </>
          )}
        </div>
      </div>
      <Input placeholder={t.UI.singleList.search} onChange={setSearch} value={search} className="mt-5" />
      {Array.isArray(list) && (
        <div className="mt-3">
          {list.filter(filter(search)).length}
          {t.UI.singleList.resultAmountSuffix}
        </div>
      )}
      <div className="mt-0">
        {Array.isArray(list) && list.length > 0 && (
          <>
            <div className="flex flex-col lg:flex-row">
              {Object.keys(propParser).map((k) => (
                <div className="flex-1 shrink-0" key={k}>
                  <div className="font-bold">{translationObject[k]}</div>
                </div>
              ))}
              {(!!Update || !!linkGenerator || !!Action) && !selectActive && <div className="w-28 shrink-0"></div>}
              {!!selectActive && (
                <div className="w-28 shrink-0">
                  <Checkbox checked={isToggleAllActive()} onChange={toggleAll} />
                </div>
              )}
            </div>
            {list.filter(filter(search)).map((item) => {
              return (
                <div className="flex flex-col lg:flex-row items-center" key={item.uuid}>
                  {Object.keys(propParser).map((p) => (
                    <div className="flex-1 shrink-0 truncate overflow-hidden" key={p}>
                      {propParser[p](item)}
                    </div>
                  ))}
                  {(!!Update || !!linkGenerator || !!Action) && !selectActive && (
                    <div className="w-28 flex justify-between gap-3 shrink-0">
                      {!!Update && <Update successCallback={pull} initData={item} />}
                      {!!Action && (
                        <>
                          {Array.isArray(Action) && Action.map((A, i) => <A key={i} initData={item} />)}
                          {!Array.isArray(Action) && <Action initData={item} />}
                        </>
                      )}
                      {!!linkGenerator && (
                        <a href={linkGenerator(item)}>
                          <EyeIcon className="h-5 fill-blue" />
                        </a>
                      )}
                    </div>
                  )}
                  {selectActive && (
                    <div className="w-28 shrink-0">
                      <Checkbox checked={selectedItemUUIDList.includes(item.uuid)} onChange={toggleItemSelection(item.uuid)} />
                    </div>
                  )}
                </div>
              )
            })}
          </>
        )}
        {(!list || list.length === 0) && <div className="text-gray text-center text-sm">{t.UI.singleList.noData}</div>}
      </div>
      {selectActive && selectedItemUUIDList.length > 0 && (
        <div className="p-3 fixed bottom-0 left-0 w-full bg-blue">
          <div className="flex flex-col gap-3 md:flex-row justify-end">
            <Dropdown
              className="!w-fit"
              items={multiOptions.map((o) => ({ label: o.label, value: o.value }))}
              value={selectedMultiOption}
              onChange={setSelectedMultiOption}
            />
            {!!selectedMultiOption && <Button onClick={triggerOption} light className="bg-lightGray" text={t.UI.multiList.cta} />}
          </div>
        </div>
      )}
    </div>
  )
}

export default SimpleList
