import props from '../../../../../redux/props'
import { Event } from '../../../../../types/Event'
import { EventOption } from '../../../../../types/EventOption'
import Accordion from '../../../../UI/Accordion/Accordion'
import Modal from '../../../../UI/Modal/Modal'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { QueueListIcon } from '@heroicons/react/24/solid'
import Add from './Option/Add'
import Upsert from './Option/Upsert'

type Props = {
  initData: Event
  successCallback: (payload: Event) => void
}

const OptionManager: React.FC<Props> = ({ initData }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<EventOption[]>(initData.options)

  const toggle = () => setOpen(!open)

  const updateOption = (uuid: string) => (option?: EventOption) => {
    if (!option) {
      const newOptions = options.filter((o) => o.uuid !== uuid)
      setOptions(newOptions)
    } else {
      const newOptions = options.map((o) => {
        if (o.uuid === uuid) {
          return option
        }
        return o
      })
      setOptions(newOptions)
    }
  }

  const getItems = () => {
    return options.map((option) => {
      return {
        key: option.uuid,
        title: option.name,
        content: <Upsert initData={option} callback={updateOption(option.uuid)} />,
      }
    })
  }

  const add = (option: EventOption) => {
    setOptions([...options, option])
  }

  return (
    <>
      <QueueListIcon className="h-5 fill-blue cursor-pointer" onClick={toggle} />
      <Modal noForm large show={open} onClose={toggle} title={t.admin.event.option.title}>
        <Accordion items={getItems()} />
        <Add eventUUID={initData.uuid} callback={add} />
      </Modal>
    </>
  )
}

export default OptionManager
