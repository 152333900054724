import { useState } from 'react'
import { EventOption } from '../../../../../../types/EventOption'
import Button from '../../../../../UI/Button/Button'
import Form from '../../../../../UI/Form/Form'
import useToast, { Type } from '../../../../../hooks/useToast'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import Input from '../../../../../UI/Input/Input'
import Number from '../../../../../UI/Input/Number'
import Textarea from '../../../../../UI/Textarea/Textarea'
import { crud } from '@think-internet/zeus-frontend-package'
import _ from 'lodash'

type Props = {
  initData: EventOption
  callback: (payload?: EventOption) => void
}

const Upsert: React.FC<Props> = ({ initData, callback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const eventOptionFeature = crud.use(props.EVENT_OPTION)
  const [option, setOption] = useState<EventOption>(initData)

  const updateProp = (key: string) => (value: any) => {
    const newOption = { ...option }
    _.set(newOption, key, value)
    setOption(newOption)
  }

  const cancelOrRemove = async () => {
    if (!!initData && !!initData.uuid) {
      await eventOptionFeature.remove({ uuid: initData.uuid })
      callback()
    } else {
      callback()
      setOption(initData)
    }
  }
  const save = async () => {
    const response = await eventOptionFeature.upsert(option)
    if (!!response) {
      callback(response)
      toast(Type.SUCCESS, t.admin.event.option.add.success)
    } else {
      toast(Type.ERROR, t.admin.event.option.add.error)
    }
  }

  return (
    <Form onSubmit={save}>
      <Input required label={t.admin.event.option.attributes.name} value={option.name} onChange={updateProp('name')} />
      <Input required label={t.admin.event.option.attributes.identifier} value={option.identifier} onChange={updateProp('identifier')} />
      <Input required label={t.admin.event.option.attributes.location} value={option.location} onChange={updateProp('location')} />
      <Input required label={t.admin.event.option.attributes.locationLink} value={option.locationLink} onChange={updateProp('locationLink')} />
      <Input
        type="datetime-local"
        required
        label={t.admin.event.option.attributes.dateTime}
        value={option.dateTime}
        onChange={updateProp('dateTime')}
      />
      <Number required label={t.admin.event.option.attributes.maxAttendees} value={option.maxAttendees} onChange={updateProp('maxAttendees')} />
      <Input
        type="datetime-local"
        required
        label={t.admin.event.option.attributes.registrationPossibleUntil}
        value={option.registrationPossibleUntil}
        onChange={updateProp('registrationPossibleUntil')}
      />
      <Number
        required
        label={t.admin.event.option.attributes.durationInHours}
        value={option.durationInHours}
        onChange={updateProp('durationInHours')}
      />
      <Input
        required
        label={t.admin.event.option.attributes.welcomeSubject}
        value={option.welcomeMail.subject}
        onChange={updateProp('welcomeMail.subject')}
      />
      <Textarea
        required
        label={t.admin.event.option.attributes.welcomeBody}
        value={option.welcomeMail.body}
        onChange={updateProp('welcomeMail.body')}
      />
      <div className="flex gap-3 items-center justify-between">
        <Button inverted text={!!initData.uuid ? t.admin.event.option.add.removeCTA : t.admin.event.option.add.cancelCTA} onClick={cancelOrRemove} />
        <Button text={t.admin.event.option.add.saveCTA} type="submit" />
      </div>
    </Form>
  )
}

export default Upsert
