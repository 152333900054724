import { useState } from 'react'
import { Contact } from '../../../../../types/Contact'
import MultiList from '../../../../UI/MultiList/MultiList'
import props from '../../../../../redux/props'
import { crud, functional } from '@think-internet/zeus-frontend-package'
import useToast, { Type } from '../../../../hooks/useToast'
import { useSelector } from 'react-redux'
import { Event } from '../../../../../types/Event'
import Import from './Import'
import SendInvitation from './SendInvitation'
import { getPrettyDateTime } from '../../../../../utility'
import Routes from '../../../../../redux/routes'

type Props = {
  event: Event
}

enum MultiOption {
  SEND_INVITATION = 'SEND_INVITATION',
}

const ContactList: React.FC<Props> = ({ event }) => {
  const [contacts, setContacts] = useState<Contact[]>()
  const contactFeature = crud.use(props.CONTACT)
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])
  const sendInvitation = functional.use(Routes.SEND_INVITATION)

  const filter = (search: string) => (item: Contact) => {
    return (
      item.firstname.toLowerCase().includes(search.toLowerCase()) ||
      item.lastname.toLowerCase().includes(search.toLowerCase()) ||
      item.mail.toLowerCase().includes(search.toLowerCase()) ||
      item.tags.includes(search.toLowerCase())
    )
  }

  const pull = async () => {
    const contacts = await contactFeature.list({ eventUUID: event.uuid })
    if (Array.isArray(contacts)) {
      setContacts(contacts)
    } else {
      toast(Type.ERROR, t.admin.event.list.error)
    }
  }

  const propParser = {
    salutation: (item: Contact) => item.salutation,
    firstname: (item: Contact) => item.firstname,
    lastname: (item: Contact) => item.lastname,
    mail: (item: Contact) => item.mail,
    tags: (item: Contact) => item.tags.join(', '),
    invitationSentDate: (item: Contact) => (item.invitationSentDate ? getPrettyDateTime(item.invitationSentDate as unknown as string) : '-'),
  }

  const multiOptions = Object.keys(MultiOption).map((o) => ({ value: o, label: t.admin.event.detail.contactList.multiOption[o] }))

  const triggerMultiOption = async (option: MultiOption, uuidList: string[]) => {
    if (uuidList.length === 0) return false
    if (option === MultiOption.SEND_INVITATION) {
      const status = await sendInvitation({ eventUUID: event.uuid, uuidList })
      if (status) {
        toast(Type.SUCCESS, t.admin.event.detail.contactList.invite.success)
      } else {
        toast(Type.ERROR, t.admin.event.detail.contactList.invite.error)
      }
    }
  }

  return (
    <div>
      <MultiList
        title={`${event.name}${t.admin.event.detail.contactList.titleSuffix}`}
        pull={pull}
        filter={filter}
        list={contacts}
        propParser={propParser}
        translationObject={t.admin.contact.attributes}
        Add={Import}
        Action={SendInvitation}
        multiOptions={multiOptions}
        triggerMultiOption={triggerMultiOption}
      />
    </div>
  )
}

export default ContactList
