import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { functional } from '@think-internet/zeus-frontend-package'
import props from '../../redux/props'
import Routes from '../../redux/routes'
import { setLocal } from '../../redux/action/local'
import { State } from '../../types/State'

const useStateManager = () => {
  const state = useSelector((s) => s[props.STATE])
  const loadState = functional.use(Routes.LOAD_STATE)
  const dispatch = useDispatch()

  useEffect(() => {
    const get = async () => {
      const state: State = await loadState({ hello: 'world' })
      dispatch(setLocal(props.STATE, state))
    }
    if (!state) get()
  }, [state, loadState, dispatch])

  return state
}

export default useStateManager
