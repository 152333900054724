export const translation = {
  projectName: 'Event-Hub',

  header: {
    navigation: {
      default: [
        {
          name: 'Login',
          link: '/login',
        },
      ],
      ADMIN: [
        {
          name: 'Event',
          link: '/admin/event/list',
        },
      ],
      isLoggedIn: {
        name: 'Abmelden',
      },
    },
  },

  admin: {
    event: {
      list: {
        headline: 'Events',
        error: 'Events konnten nicht geladen werden.',
      },
      add: {
        title: 'Event hinzufügen',
        updateTitle: 'Event bearbeiten',
        cta: 'Erstellen',
        error: 'Event konnte nicht erstellt werden.',
      },
      detail: {
        error: 'Event konnte nicht geladen werden.',
        contactList: {
          titleSuffix: ' - Kontakte',
          import: {
            title: 'Kontakte importieren',
            tags: 'Tags (Komma getrennt)',
            cta: 'Importieren',
          },
          invite: {
            title: 'Einladung senden',
            hint: 'Sind Sie sicher, dass Sie die Einladung an diesen Kontakt senden möchten?',
            alreadySentPrefix: 'Einladung bereits versendet am: ',
            success: 'Einladungen wurden erfolgreich versendet.',
            error: 'Einladungen konnten nicht versendet werden.',
          },
          multiOption: {
            SEND_INVITATION: 'Einladung senden',
          },
          error: 'Kontakte konnten nicht geladen werden.',
        },
      },
      access: {
        title: 'Zugang',
        success: 'Zugang erfolgreich gespeichert.',
        error: 'Zugang konnte nicht gespeichert werden.',
        attributes: {
          identifier: 'E-Mail Adresse',
          password: 'Passwort',
        },
      },
      attributes: {
        name: 'Name',
        origin: 'Domain',
        senderMail: 'Absender E-Mail',
        inviteSubject: 'Betreff der Einladungsmail',
        inviteBody: 'HTML-Inhalt der Einladungsmail',
      },
      option: {
        title: 'Optionen',
        add: {
          startCTA: '+ hinzufügen',
          saveCTA: 'Speichern',
          cancelCTA: 'Abbrechen',
          removeCTA: 'Löschen',
          error: 'Option konnte nicht gespeichert werden.',
          success: 'Option erfolgreich gespeichert.',
        },
        attributes: {
          name: 'Name',
          identifier: 'Kennung',
          dateTime: 'Datum und Uhrzeit',
          durationInHours: 'Dauer in Stunden',
          location: 'Ort',
          locationLink: 'Ort (Google-Maps-Link)',
          welcomeSubject: 'Betreff der Willkommensmail',
          welcomeBody: 'HTML-Inhalt der Willkommensmail',
          maxAttendees: 'Maximale Teilnehmeranzahl',
          registrationPossibleUntil: 'Anmeldung möglich bis',
        },
      },
    },
    contact: {
      attributes: {
        salutation: 'Anrede',
        firstname: 'Vorname',
        lastname: 'Nachname',
        mail: 'E-Mail Adresse',
        tags: 'Tags',
        invitationSentDate: 'Eingelanden am',
      },
    },
  },

  attendee: {
    attributes: {
      firstname: 'Vorname',
      lastname: 'Nachname',
      mail: 'E-Mail Adresse',
      withExtraPerson: 'Begleitung',
      firstnameExtra: 'Vorname (Begleitung)',
      lastnameExtra: 'Nachname (Begleitung)',
      attendedEvent: 'Teilnahme',
      extraAttendedEvent: 'Teilnahme (Begleitung)',
    },
  },

  login: {
    headline: 'Login',
    cta: 'Anmelden',
    error: 'Anmeldung fehlgeschlagen. Falsche E-Mail oder Passwort.',
  },

  footer: {
    links: [
      { name: 'Impressum', link: 'https://www.think-internet.de/imprint' },
      { name: 'Datenschutz', link: 'https://www.think-internet.de/data-protection' },
      { name: 'AGB', link: 'https://www.think-internet.de/gtc' },
    ],
    dataProtection: 'Datenschutz',
    imprint: 'Impressum',
    copyright: `Copyright © ${new Date().getFullYear()}`,
  },

  generic: {
    company: {
      name: 'Think Internet Operations GmbH',
      street: 'Ulmenweg 2',
      city: '87779 Trunkelsberg',
      mail: 'office@think-internet.de',
    },
    timestampPrefix: 'Erstellt am: ',
    mailPrefix: 'E-Mail: ',
    websitePrefix: 'Webseite: ',
    employeeSuffix: ' Mitarbeiter',
    salutation: 'Anrede',
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    birthday: 'Geburtsdatum',
    street: 'Straße, Hausnummer',
    companyName: 'Firmenname',
    club: 'Verein (optional)',
    city: 'PLZ, Ort',
    mail: 'E-Mail-Adresse',
    identifier: 'E-Mail-Adresse',
    message: 'Ihre Anfrage...',
    phone: 'Telefonnummer',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    createdOn: 'Erstellt am',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    send: 'Senden',
    error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.',
    acceptAGB: 'Ich akzeptiere die AGB',
    percent: ' %',
    kilometerSuffix: ' km',
    upload: 'Hochladen',
    login: 'Anmelden',
    logout: 'Abmelden',
    export: 'Export',
    import: 'Import',
  },

  UI: {
    singleList: {
      search: 'Suche',
      resultAmountSuffix: ' Ergebnisse',
      noData: 'Keine Daten vorhanden',
    },
    multiList: {
      search: 'Suche',
      resultAmountSuffix: ' Ergebnisse',
      noData: 'Keine Daten vorhanden',
      cta: 'Ausführen',
      cancelCTA: 'Abbrechen',
      option: {
        EXPORT: 'Export',
        PROCESS: 'Verarbeiten',
        STOP: 'Stoppen',
      },
      export: {
        title: 'Export',
        identifier: 'Kennung',
      },
      error: 'Aktion konnte nicht ausgeführt werden',
    },
    csvImport: {
      title: 'Import',
      startCTA: 'Import starten',
      loading: 'Daten werden imporiert...',
      error: 'Der Import ist fehlgeschlagen.',
    },
    dropdown: {
      emptySelection: 'Bitte wählen',
    },
    selectableTagList: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger',
    },
    textarea: {
      max: {
        prefix: 'maximal ',
        suffix: ' Zeichen',
      },
    },
    fileSelect: {
      fileToLargePrefix: 'Die ausgewählte Datei ist zu groß: ',
      allowedFileTypes: 'Erlaubte Dateitypen: ',
      allowedFileSize: 'Max. erlaubte Dateigröße: ',
    },
    dnd: {
      text: 'Bewerbung anhängen',
      hint: '(Erlaubt ist eine PDF-Datei bis zu 25 MB)',
      selectedPrefix: 'Gewählte PDF: ',
      error: {
        size: 'Deine Ausgewählte PDF ist zu groß. Maximal erlaubte Dateigröße: 25 MB',
        type: 'Bitte lade nur PDF-Dateien hoch.',
      },
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwörter stimmen nicht überein',
    passwordReset: 'Passwort wurde erfolgreich geändert',
    save: 'Ändern',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    title: 'Passwort ändern',
  },
}

export const langKey = 'DE'
