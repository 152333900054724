import { ReactNode } from 'react'
import { formatFileSize, getUUID } from '../../../utility'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'

interface IFileSelect {
  label?: string
  onFiles?: (file: File[]) => void
  acceptedMimeTypes?: string[]
  children?: ReactNode
  multiple?: boolean
  maxFileSize?: number
  showAllowedLabels?: boolean
}

const FileSelect: React.FC<IFileSelect> = ({
  label,
  onFiles,
  acceptedMimeTypes = null,
  children,
  multiple = false,
  maxFileSize,
  showAllowedLabels = true,
}) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const uuid = getUUID()

  const changed = (e: any) => {
    if (e.target.files.length > 0) {
      if (maxFileSize) {
        const files: File[] = Array.from(e.target.files)
        const tooLargeFiles = files.filter((f) => f.size > maxFileSize)
        if (tooLargeFiles.length > 0) {
          tooLargeFiles.forEach((f) => {})
        } else {
          multiple ? onFiles(Array.from(e.target.files)) : onFiles([e.target.files[0]])
        }
      } else {
        multiple ? onFiles(Array.from(e.target.files)) : onFiles([e.target.files[0]])
      }
    }
  }

  return (
    <div className={`form-control w-full h-full`}>
      {!!label && !children && (
        <label className="w-full h-full flex items-center justify-center label pt-0" htmlFor={uuid}>
          {!children && <span className="label-text">{label}</span>}
        </label>
      )}
      {children && (
        <label className="w-full h-full flex items-center justify-center cursor-pointer" htmlFor={uuid}>
          {children}
        </label>
      )}
      <input
        type="file"
        id={uuid}
        accept={acceptedMimeTypes ? acceptedMimeTypes.join(',') : ''}
        onChange={changed}
        multiple={multiple}
        className={`file-input file-input-bordered file-input-sm file-input-ghost ${!!children ? ' hidden' : ''}`}
      />
      {showAllowedLabels && Array.isArray(acceptedMimeTypes) && acceptedMimeTypes.length > 0 && (
        <div className="text-xs text-black-secondary">
          {t.UI.fileSelect.allowedFileTypes}
          {acceptedMimeTypes
            .filter((type) => !type.includes('*') && type.includes('/'))
            .map((type) => type.split('/')[1])
            .join(', ')}
        </div>
      )}
      {showAllowedLabels && isFinite(maxFileSize) && (
        <div className="text-xs text-black-secondary">
          {t.UI.fileSelect.allowedFileSize}
          {formatFileSize(maxFileSize)}
        </div>
      )}
    </div>
  )
}

export default FileSelect
