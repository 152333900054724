import { useParams } from 'react-router-dom'
import LargeContainer from '../../../UI/Container/LargeContainer'
import { useEffect, useState } from 'react'
import { Event } from '../../../../types/Event'
import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../../redux/props'
import useToast, { Type } from '../../../hooks/useToast'
import { useSelector } from 'react-redux'
import Loading from '../../../UI/Loading/Loading'
import ContactList from './ContactList/ContactList'

const Detail = () => {
  const { uuid } = useParams()
  const [event, setEvent] = useState<Event>()
  const eventFeature = crud.use(props.EVENT)
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    const get = async () => {
      const event = await eventFeature.get({ uuid })
      if (!!event) {
        setEvent(event)
      } else {
        toast(Type.ERROR, t.admin.event.detail.error)
      }
    }
    if (!event) {
      get()
    }
  }, [event, uuid, eventFeature, toast, t])

  return (
    <div className="my-10">
      <LargeContainer>
        <Loading loading={event} />
        {event && (
          <div>
            <ContactList event={event} />
          </div>
        )}
      </LargeContainer>
    </div>
  )
}

export default Detail
