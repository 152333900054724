import Form from '../UI/Form/Form'
import Layout from '../UI/Layout/Layout'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { auth } from '@think-internet/zeus-frontend-package'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import props from '../../redux/props'
import { Level } from '../../types/Auth'
import Button from '../UI/Button/Button'
import SmallContainer from '../UI/Container/SmallContainer'
import Input from '../UI/Input/Input'
import useToast, { Type } from '../hooks/useToast'

const Login = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [mail, setMail] = useState('')
  const [password, setPassword] = useState('')
  const cookies = new Cookies()
  const navigate = useNavigate()
  const toast = useToast()

  const submit = async () => {
    const token = await auth.login(mail, password)

    if (token) {
      cookies.set('token', token, { path: '/' })

      const accountLink = await auth.resolveToken(token)

      if (accountLink) {
        if (accountLink.level === Level.ADMIN) {
          navigate('/admin')
        }
      }
    } else {
      toast(Type.ERROR, t.login.error)
    }
  }

  return (
    <Layout authLevelRequired={false}>
      <SmallContainer className="pt-52">
        <div className="mt-5 max-w-xs mx-auto">
          <Form onSubmit={submit}>
            <div className="font-bold text-center text-blue text-lg">{t.login.headline}</div>
            <div className="flex gap-2 mt-2 flex-col items-end">
              <Input placeholder={t.generic.mail} onChange={setMail} value={mail} type="email" required={true} />
              <Input placeholder={t.generic.password} onChange={setPassword} value={password} required={true} type="password" />
              <Button type="submit" text={t.login.cta} />
            </div>
          </Form>
        </div>
      </SmallContainer>
    </Layout>
  )
}

export default Login
