import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import Modal from '../../../../UI/Modal/Modal'
import { PaperAirplaneIcon } from '@heroicons/react/24/solid'
import { Contact } from '../../../../../types/Contact'
import Routes from '../../../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import useToast, { Type } from '../../../../hooks/useToast'
import { getPrettyDateTime } from '../../../../../utility'

type Props = {
  initData: Contact
  successCallback: () => void
}

const SendInvitation: React.FC<Props> = ({ initData }) => {
  const [open, setOpen] = useState(false)
  const t = useSelector((s) => s[props.TRANSLATION])
  const sendInvitation = functional.use(Routes.SEND_INVITATION)
  const toast = useToast()

  const toggle = () => setOpen(!open)

  const send = async () => {
    const status = await sendInvitation({ eventUUID: initData.eventUUID, uuidList: [initData.uuid] })
    if (status) {
      toggle()
      toast(Type.SUCCESS, t.admin.event.detail.contactList.invite.success)
    } else {
      toast(Type.ERROR, t.admin.event.detail.contactList.invite.error)
    }
  }

  return (
    <div>
      <PaperAirplaneIcon className="h-5 fill-blue cursor-pointer" onClick={toggle} />
      <Modal onConfirm={send} show={open} onClose={toggle} title={t.admin.event.detail.contactList.invite.title}>
        <div>{t.admin.event.detail.contactList.invite.hint}</div>
        {!!initData.invitationSentDate && (
          <div className="font-bold mt-3">{`${t.admin.event.detail.contactList.invite.alreadySentPrefix}${getPrettyDateTime(
            initData.invitationSentDate as unknown as string,
          )}`}</div>
        )}
      </Modal>
    </div>
  )
}

export default SendInvitation
