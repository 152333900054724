import { useState } from 'react'
import CSVImport, { Type as CSVType } from '../../../../UI/CSVImport/CSVImport'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import Button from '../../../../UI/Button/Button'
import Modal from '../../../../UI/Modal/Modal'
import { useParams } from 'react-router-dom'
import Input from '../../../../UI/Input/Input'

type Props = {
  eventUUID: string
  successCallback: () => void
}

const Import: React.FC<Props> = ({ successCallback }) => {
  const [open, setOpen] = useState(false)
  const t = useSelector((s) => s[props.TRANSLATION])
  const { uuid } = useParams()
  const [tags, setTags] = useState<string>('')

  const toggle = () => setOpen(!open)

  const done = () => {
    setTags('')
    toggle()
    successCallback()
  }

  return (
    <div>
      <Button text={t.admin.event.detail.contactList.import.cta} onClick={toggle} />
      <Modal show={open} onClose={toggle} title={t.admin.event.detail.contactList.import.title}>
        <Input value={tags} onChange={setTags} placeholder={t.admin.event.detail.contactList.import.tags} />
        <CSVImport type={CSVType.CONTACT} className="mt-3" metaData={{ eventUUID: uuid, tags }} successCallback={done} />
      </Modal>
    </div>
  )
}

export default Import
