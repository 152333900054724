import { Navigate, Route, Routes } from 'react-router-dom'
import List from './List/List'
import Detail from './Detail/Detail'

const Event = () => {
  return (
    <Routes>
      <Route path={'list'} element={<List />} />
      <Route path={':uuid'} element={<Detail />} />
      <Route path={'*'} element={<Navigate replace to="/admin/event/list" />} />
    </Routes>
  )
}

export default Event
