import { useSelector } from 'react-redux'
import Button from '../../../UI/Button/Button'
import Modal from '../../../UI/Modal/Modal'
import props from '../../../../redux/props'
import { useState } from 'react'
import { Event } from '../../../../types/Event'
import { crud } from '@think-internet/zeus-frontend-package'
import Input from '../../../UI/Input/Input'
import _ from 'lodash'
import useToast, { Type } from '../../../hooks/useToast'
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/solid'
import Textarea from '../../../UI/Textarea/Textarea'

type Props = {
  initData?: Event
  successCallback: () => void
}

const Upsert: React.FC<Props> = ({ initData, successCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const [data, setData] = useState<Event>(
    initData || {
      inviteMail: {
        subject: '',
        body: '',
      },
      options: [],
    },
  )
  const [open, setOpen] = useState(false)
  const eventFeature = crud.use(props.EVENT)

  const toggle = () => setOpen(!open)

  const upsert = async () => {
    const response = await eventFeature.upsert(data)
    if (!!response) {
      successCallback()
      toggle()
    } else {
      toast(Type.ERROR, t.admin.event.add.error)
    }
  }

  const updateProp = (key: string) => (value: any) => {
    const newData = { ...data }
    _.set(newData, key, value)
    setData(newData)
  }

  return (
    <>
      {!!initData && <AdjustmentsVerticalIcon className="h-5 fill-blue cursor-pointer" onClick={toggle} />}
      {!initData && <Button text={t.admin.event.add.cta} onClick={toggle} />}
      <Modal large onConfirm={upsert} show={open} onClose={toggle} title={data.uuid ? t.admin.event.add.updateTitle : t.admin.event.add.title}>
        <div className="flex first-line:flex-col lg:flex-row gap-2">
          <div className="flex-1 flex flex-col gap-2">
            <Input required label={t.admin.event.attributes.name} value={data.name} onChange={updateProp('name')} />
            <Input required label={t.admin.event.attributes.senderMail} value={data.senderMail} onChange={updateProp('senderMail')} />
            <Input required label={t.admin.event.attributes.origin} value={data.origin} onChange={updateProp('origin')} />
            <Input
              required
              label={t.admin.event.attributes.inviteSubject}
              value={data.inviteMail.subject}
              onChange={updateProp('inviteMail.subject')}
            />
            <Textarea required label={t.admin.event.attributes.inviteBody} value={data.inviteMail.body} onChange={updateProp('inviteMail.body')} />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Upsert
