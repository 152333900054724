import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { ProgressCallback } from '@think-internet/zeus-frontend-package/functionalInterface/types'
import { Type } from './CSVImport'

type Return = (file: File, metaData: Object, progressCallback?: ProgressCallback) => Promise<false | any[]>

const useCSVImport = (type: Type): Return => {
  const csvImport = functional.use(Routes.CSV_IMPORT)

  const start: Return = async (file, metaData, progressCallback) => {
    const data = await csvImport({ type, metaData: metaData ? JSON.stringify(metaData) : null }, null, null, file, progressCallback)
    return Array.isArray(data) ? data : false
  }

  return start
}

export default useCSVImport
