import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import { Level } from '../../types/Auth'
import Event from './Event/Event'

const Admin = () => {
  return (
    <Layout authLevelRequired={[Level.ADMIN]}>
      <Routes>
        <Route path={'event/*'} element={<Event />} />
        <Route path={'*'} element={<Navigate replace to="/admin/event" />} />
      </Routes>
    </Layout>
  )
}

export default Admin
