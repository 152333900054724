import { useState } from 'react'
import { Event } from '../../../../types/Event'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import Container from '../../../UI/Container/Container'
import MultiList from '../../../UI/MultiList/MultiList'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type } from '../../../hooks/useToast'
import Upsert from './Upsert'
import Access from './Access'
import OptionManager from './OptionManager/OptionManager'

const List = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [events, setEvents] = useState<Event[]>()
  const eventFeature = crud.use(props.EVENT)
  const toast = useToast()

  const filter = (search: string) => (item: Event) => {
    return item.name.toLowerCase().includes(search.toLowerCase())
  }

  const pull = async () => {
    const events = await eventFeature.list({})
    if (Array.isArray(events)) {
      setEvents(events)
    } else {
      toast(Type.ERROR, t.admin.event.list.error)
    }
  }

  const propParser = {
    name: (item: Event) => item.name,
    origin: (item: Event) => item.origin,
  }

  return (
    <div className="my-10">
      <Container>
        <MultiList
          title={t.admin.event.list.headline}
          pull={pull}
          filter={filter}
          list={events}
          propParser={propParser}
          translationObject={t.admin.event.attributes}
          Add={Upsert}
          Update={Upsert}
          Action={[OptionManager, Access]}
          linkGenerator={(item) => `/admin/event/${item.uuid}`}
        />
      </Container>
    </div>
  )
}

export default List
