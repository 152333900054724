type Props = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
  label?: string | boolean
  onChange?: (value: string) => void
  value: string
  placeholder?: string
  disabled?: boolean
  className?: string
  rows?: number
  required?: boolean
  id?: string
  minCharacters?: number
  maxCharacters?: number
  useRef?: any
}

const Textarea: React.FC<Props> = ({
  label,
  onChange,
  value,
  placeholder,
  disabled,
  className,
  rows = 3,
  required = false,
  id,
  minCharacters,
  maxCharacters,
  useRef,
}) => {
  return (
    <div className={`w-full ${className}`}>
      {label && (
        <label htmlFor={id} className="text-gray text-sm">
          {typeof label === 'string' ? label : placeholder}
        </label>
      )}
      <textarea
        id={id}
        ref={useRef}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={placeholder}
        required={required}
        className={`border-b border-blue placeholder-gray w-full py-2 px-1 bg-white text-gray rounded-t-sm focus:outline-none focus:shadow-outline`}
        rows={rows}
        minLength={minCharacters}
        maxLength={maxCharacters}
      />
    </div>
  )
}

export default Textarea
