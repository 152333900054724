import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../../redux/props'
import useToast, { Type } from '../../../hooks/useToast'
import Modal from '../../../UI/Modal/Modal'
import { Event } from '../../../../types/Event'
import Input from '../../../UI/Input/Input'
import { Member } from '../../../../types/Member'

type Props = {
  initData: Event
  successCallback: () => void
}

const Access: React.FC<Props> = ({ initData, successCallback }) => {
  const [open, setOpen] = useState(false)
  const t = useSelector((s) => s[props.TRANSLATION])
  const memberFeature = crud.use(props.MEMBER)
  const toast = useToast()
  const [member, setMember] = useState<Member>()

  useEffect(() => {
    const pull = async () => {
      const member = await memberFeature.get({ eventUUID: initData.uuid })
      if (member) {
        setMember(member)
      } else {
        setMember({ eventUUID: initData.uuid })
      }
    }
    if (!member || (!!member && member.eventUUID !== initData.uuid)) {
      pull()
    }
  })

  const toggle = () => setOpen(!open)

  const save = async () => {
    const status = await memberFeature.upsert(member)
    if (status) {
      toggle()
      toast(Type.SUCCESS, t.admin.event.access.success)
    } else {
      toast(Type.ERROR, t.admin.event.access.error)
    }
  }

  const updateProp = (prop: string) => (value: string) => setMember({ ...member, [prop]: value })

  if (!member) return <></>
  return (
    <div>
      <LockClosedIcon className="h-5 fill-blue cursor-pointer" onClick={toggle} />
      <Modal onConfirm={save} show={open} onClose={toggle} title={t.admin.event.access.title}>
        <Input required value={member.identifier} onChange={updateProp('identifier')} label={t.admin.event.access.attributes.identifier} />
        <Input required type="password" value={member.password} onChange={updateProp('password')} label={t.admin.event.access.attributes.password} />
      </Modal>
    </div>
  )
}

export default Access
