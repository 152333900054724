import { useState } from 'react'
import { EventOption } from '../../../../../../types/EventOption'
import Button from '../../../../../UI/Button/Button'
import props from '../../../../../../redux/props'
import { useSelector } from 'react-redux'
import Upsert from './Upsert'

type Props = {
  eventUUID: string
  callback: (option: EventOption) => void
}

const Add: React.FC<Props> = ({ eventUUID, callback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState<EventOption>()

  const add = () => {
    setData({
      eventUUID: eventUUID,
      welcomeMail: {
        subject: '',
        body: '',
      },
    })
  }

  const internalCallback = (option?: EventOption) => {
    if (!!option) {
      callback(option)
    }
    setData(null)
  }

  return (
    <div>
      {!!data && (
        <>
          <Upsert initData={data} callback={internalCallback} />
        </>
      )}
      {!data && <Button className="mx-auto mt-3" text={t.admin.event.option.add.startCTA} onClick={add} />}
    </div>
  )
}

export default Add
